<template>
	<modal class="NoxModalInfoStatsBotLogs" name="NoxModalInfoStatsBotLogs" modal-width="600" :width="600" :height="'auto'" :adaptive="true" :scrollable="true" :clickToClose="true" @before-open="beforeOpen">
		<div class="nox_modal">
			<div class="nox_modal_open_loading" v-if="noxIsOpenLoading"><div class="nox_loading"></div></div>
			<div class="nox_modal_open" v-else>
				<div class="nox_modal_head">
					<div class="nox_modal_close" @click="close"><font-awesome-icon :icon="['fas', 'times']" /></div>
					<div class="nox_modal_title">
						<span v-if="noxType == 'getBotLogs'" v-html="$store.getters.getLanguageText('5.6', 44)"></span>
					</div>
				</div>
				<div class="nox_modal_body">
					<div class="nox_modal_info">
						<div v-if="noxType == 'getBotLogs'">
							<div class="nox_modal_info_bot_logs" v-if="noxBotLogsData.length">
								<div class="nox_modal_info_bot_logs_item" v-for="(value, index) in noxBotLogsData" :key="index">
									<span>[{{ new Date(value.created_at * 1000).toLocaleString($store.state.noxLanguage) }}]:</span>
									<span v-html="$parent.$parent.$parent.getDescription('3.4.4', value.details)"></span>
								</div>
							</div>
							<div v-else v-html="$store.getters.getLanguageText('5.6', 46)"></div>
						</div>
					</div>
					<div class="nox_modal_alert" v-html="noxAlert"></div>
					<div class="nox_modal_loading" :class="{ hide: !noxIsLoading }"><img src="@/assets/images/loading.gif"></div>
					<div class="nox_modal_buttons" :class="{ hide: noxIsLoading }">
						<button type="button" class="nox_button common" @click="close" v-html="$store.getters.getLanguageText('1.1', 3)"></button>
						<button type="button" class="nox_button common" @click="axios" v-if="noxType == 'getBotLogs' && noxBotLogsDataLimitOffset" v-html="$store.getters.getLanguageText('1.1', 20)"></button>
					</div>
				</div>
			</div>
		</div>
	</modal>
</template>

<script>
	import axios from 'axios'
	export default {
		data: () => ({
			noxTemp: '',
			noxAlert: '',
			noxId: 0,
			noxType: '',
			noxIndex: 0,
			noxBotLogsData: [],
			noxBotLogsDataTableOffset: 0,
			noxBotLogsDataLimitOffset: 0,
			noxBotLogsDataLimitCount: 30,
			noxIsOpenLoading: false,
			noxIsLoading: false,
			noxIsError: false
		}),
		methods: {
			beforeOpen: function(event) {

				this.noxTemp = '';
				this.resetAlerts();
				this.noxId = event.params.id;
				this.noxType = event.params.type;
				this.noxIndex = event.params.index;
				this.noxBotLogsData = [];
				this.noxBotLogsDataTableOffset = 0;
				this.noxBotLogsDataLimitOffset = 0;
				this.noxBotLogsDataLimitCount = 30;
				this.noxIsOpenLoading = false;
				this.noxIsLoading = false;
				this.noxIsError = false;

				if (this.noxType == 'getBotLogs') { this.axios(); }
			},
			resetAlerts: function() {
				this.noxAlert = '';
			},
			getError1: function(i) {

				this.noxIsError = true;

				if      (i == 1) { this.noxTemp = this.$store.getters.getLanguageText('1.3.5', 0); }
				else if (i == 2) { this.noxTemp = this.$store.getters.getLanguageText('1.3.5', 1); }
				else if (i == 3) { this.noxTemp = this.$store.getters.getLanguageText('1.3.5', 8); }
				else             { this.noxTemp = this.$store.getters.getLanguageText('1.3', 2); }

				return '<div class="alert"><b>' + this.$store.getters.getLanguageText('1.3', 0) + '</b> ' + this.noxTemp + '</div>';
			},
			getError2: function(i) {

				this.noxIsError = true;

				if      (i == 1) { this.noxTemp = this.$store.getters.getLanguageText('1.3', 3); }
				else if (i == 2) { this.noxTemp = this.$store.getters.getLanguageText('1.3', 4); }
				else if (i == 3) { this.noxTemp = this.$store.getters.getLanguageText('1.3', 5); }
				else if (i == 4) { this.noxTemp = this.$store.getters.getLanguageText('1.3', 6); }
				else if (i == 5) { this.noxTemp = this.$store.getters.getLanguageText('1.3', 7); }
				else if (i == 6) { this.noxTemp = this.$store.getters.getLanguageText('1.3', 8); }
				else if (i == 7) { this.noxTemp = this.$store.getters.getLanguageText('1.3', 9); }
				else if (i == 8) { this.noxTemp = this.$store.getters.getLanguageText('1.3', 10); }
				else if (i == 9) { this.noxTemp = this.$store.getters.getLanguageText('1.3', 11); }
				else if (i == 10) { this.noxTemp = this.$store.getters.getLanguageText('1.3', 12); }
				else              { this.noxTemp = this.$store.getters.getLanguageText('1.3', 2); }

				return '<div class="alert"><b>' + this.$store.getters.getLanguageText('1.3', 0) + '</b> ' + this.noxTemp + '</div>';
			},
			axios: function() {

				var _this = this;
				var config = {
					method: 'get', baseURL: _this.$store.state.noxApiBaseURL,
					headers: { 'Authorization': 'Bearer ' + _this.$store.state.noxInfoTokenForStats }
				};

				_this.resetAlerts();
				_this.noxIsError = false;

				if (_this.noxType == 'getBotLogs') {
					_this.noxIsLoading = true;
					_this.noxIsOpenLoading = !_this.noxBotLogsDataLimitOffset ? true : false;
					config.url = '/v2/info/stats/logs';
					config.params = { id: _this.noxId, tableOffset: _this.noxBotLogsDataTableOffset, limitOffset: _this.noxBotLogsDataLimitOffset, limitCount: _this.noxBotLogsDataLimitCount };
				}

				if (!_this.noxIsError) {
					axios(config).then(function(data) {
						if (data.status == 200) {
							if (_this.noxType == 'getBotLogs') {
								if (data.data.data.length) {
									for (var i in data.data.data) {
										_this.noxBotLogsData.push(data.data.data[i]);
									}
								}
								_this.noxBotLogsDataTableOffset = data.data.info.max_id;
								_this.noxBotLogsDataLimitOffset = data.data.info.count > _this.noxBotLogsDataLimitOffset + _this.noxBotLogsDataLimitCount ? _this.noxBotLogsDataLimitOffset + _this.noxBotLogsDataLimitCount : 0; return false;
							}
							_this.close();
						}
					}).catch(function(data) {
						if (data.response !== undefined) {
							if (data.response.status == 400) {
								if (_this.noxType == 'getBotLogs') {
									if      (data.response.data.error == 'ID_IS_EMPTY') { _this.noxAlert = _this.getError1(1); }
									else if (data.response.data.error == 'ID_NOT_VALID') { _this.noxAlert = _this.getError1(2); }
									else if (data.response.data.error == 'LIMIT_OFFSET_IS_EMPTY') { _this.noxAlert = _this.getError2(1); }
									else if (data.response.data.error == 'LIMIT_OFFSET_NOT_VALID') { _this.noxAlert = _this.getError2(2); }
									else if (data.response.data.error == 'LIMIT_COUNT_IS_EMPTY') { _this.noxAlert = _this.getError2(3); }
									else if (data.response.data.error == 'LIMIT_COUNT_NOT_VALID') { _this.noxAlert = _this.getError2(4); }
									else if (data.response.data.error == 'SORT_FIELD_IS_EMPTY') { _this.noxAlert = _this.getError2(5); }
									else if (data.response.data.error == 'SORT_FIELD_NOT_VALID') { _this.noxAlert = _this.getError2(6); }
									else if (data.response.data.error == 'SORT_ORDER_IS_EMPTY') { _this.noxAlert = _this.getError2(7); }
									else if (data.response.data.error == 'SORT_ORDER_NOT_VALID') { _this.noxAlert = _this.getError2(8); }
									else if (data.response.data.error == 'TABLE_OFFSET_IS_EMPTY') { _this.noxAlert = _this.getError2(9); }
									else if (data.response.data.error == 'TABLE_OFFSET_NOT_VALID') { _this.noxAlert = _this.getError2(10); }
								}
							}
							else if (data.response.status == 404) {
								if (_this.noxType == 'getBotLogs') {
									if (data.response.data.error == 'ID_NOT_FOUND') { _this.noxAlert = _this.getError1(3); }
								}
							}
						}
						if (!_this.noxIsError) {
							_this.noxAlert = _this.getError1(0);
						}
					}).finally(function() {
						_this.noxIsOpenLoading = false;
						_this.noxIsLoading = false;
					});
				}
			},
			close: function() {
				this.$modal.hide('NoxModalInfoStatsBotLogs');
			}
		}
	}
</script>
